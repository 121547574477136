import React, { isValidElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useDeepCompareEffect } from "react-use";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import useSetQuery, { useMultiSetQuery } from "customHooks/useQuery";
import { laoViewTableNames } from "configs/appConstants";
import { getData } from "components/common/FIlterChips";
import KCChip from "components/common/Chip";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import {
  deleteLAOView, getLAOView,
  resetLAOStatus,
  resetLAOViews,
  updateLAOView
} from "reduxLib/services/LaoServices/laoViewsServices";
import { saveFilters } from "reduxLib/services";
import { createFilterObjFromPayload } from "helpers/emeaHelpers";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import FilterViewIcon from "assets/images/table-view.svg";
import {laoFilterBox} from 'theme';
import { isEmpty } from "lodash";
import { Button, FormGroup, LinearProgress, TextField } from "@material-ui/core";
import { generateFilterLayoutTemplate, getFilterArgs } from "helpers";
import Snack from "components/common/Helpers/Snack";

const useStyles = makeStyles(laoFilterBox);

export const LaoFilterChips = ({ type, laoDeleteChip, laoRemappedFilters }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [laoChipsdata, setLaoChipsData] = useState([]);

  useDeepCompareEffect(() => {
    let tempChipData = [];
    Object.keys(laoRemappedFilters).map((key) => {
      const d = laoRemappedFilters[key];
      if (d?.type === "checkbox" || d?.type === "checkboxradio" || d?.type === "ordercheckbox") {
        d.data.map(item => {
          tempChipData.push({ ...d, ...item, type: "checkbox", parent: type, key, data: item.checked });
          return item;
        })
      } else tempChipData.push({ ...d, parent: type, key });
      return key;
    });
    setLaoChipsData(tempChipData)
  }, [laoRemappedFilters]);

  return <div data-testid="filterChips">
    {
      laoChipsdata.filter(d => d.data)
        .map((data, index) => {
          const chipdata = getData({
            ...data,
            name: t(data?.name)
          }, []);
          return ((data.data && chipdata.label) ? <KCChip
            key={index}
            deleteIcon={<HighlightOffRoundedIcon data-testid={`deletechip-${data.key}`} />}
            label={
              isValidElement(chipdata.label) ?
                chipdata.label : t(chipdata.label)
            }
            className={classes.laoChip}
            onDelete={() => laoDeleteChip(data)}
          /> : null)
        })
    }
  </div>
}

const LaoFilterBox = ({
  tableName, type, filterBody,
  columns, clearAllFilters
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [laoNewView, setLaoNewView] = useState({ open: false });
  const [params, setParams] = useSetQuery();
  const setMultiQuery = useMultiSetQuery();
  const [laoRemappedFilters, setLaoRemappedFilters] = useState({});
  const { activeView, viewLoading, loading, views, status } = useSelector(
    ({ laoViews: { activeView, viewLoading, loading, views, status } }) =>
      ({ activeView, viewLoading, loading, views, status })
  );
  const [snack, setSnack] = React.useState({
    open: false,
    severity: "error",
    message: null
  });

  const laoViewApplied = !isEmpty(activeView);

  const generateLaoURLFilters = (activeViewFilters) => {
    const args = getFilterArgs(activeViewFilters);
    return args;
  }

  useEffect(() => {
    if (status.message && status.show) {
      setSnack({
        open: true,
        severity: status.status,
        message: t(status.message)
      });
      dispatch(resetLAOStatus())
    }
  }, [status]);

  useDeepCompareEffect(() => {
    const remappedObject = createFilterObjFromPayload({
      filterPaylod: params?.laoFilters,
      filterBody,
    });
    setLaoRemappedFilters(remappedObject);
    dispatch(saveFilters({
      filter: {
        ...filterBody,
        ...remappedObject
      }, type, subtype: tableName
    }));
  }, [params?.laoFilters, tableName]); // Store is reset as per url whenever filters or tabs are changed

  useDeepCompareEffect(() => {
    if (!viewLoading && views?.length >= 0 && params.loadView) {
      const defaultParams = {
        chartType: params?.chartType ? params.chartType : "export_daily",
        tableType: params?.tableType ? params.tableType : "exportOrderDetails"
      }
      const found = views.filter(d => d.isFav)[0] || {};
      if (found.id) {
        setMultiQuery({
          viewid: found.id,
          ...defaultParams
        }); 
      } else {
        setMultiQuery({
          laoFilters: "{}",
          updateFilters: undefined,
          loadView: undefined,
          viewid: undefined,
          ...defaultParams
        });
      }
    }
  }, [params.loadView, views, viewLoading]);

  useEffect(() => {
    if (params.viewid && activeView.id !== params.viewid) {
      dispatch(getLAOView({
        viewid: params.viewid,
        tableName: laoViewTableNames[tableName]
      }));
    }

    if (params.viewid && activeView.id === params.viewid && (params.updateFilters || params.loadView)) {
      setMultiQuery({
        updateFilters: undefined,
        loadView: undefined,
        laoFilters: JSON.stringify(generateLaoURLFilters(activeView.filters))
      });
    }
  }, [params.viewid, activeView.id, params.updateFilters, params.loadView]);

  const deleteChip = (chip) => {
    const updatedobject = () => {
      return params.laoFilters?.[chip.key]?.filter(d => d !== chip.value);
    }
    setParams({
      key: "laoFilters",
      value: {
        ...params.laoFilters,
        [chip.key]: chip.type === "checkbox" ? updatedobject() : undefined
      }
    });
  }

  const laoAddView = (e) => {
    e.preventDefault();

    const template = generateFilterLayoutTemplate({
      viewName: laoNewView.name,
      filters: laoRemappedFilters,
      columns: columns
    });
    dispatch(updateLAOView({
      view: template,
      tableName: laoViewTableNames[tableName],
      updateType: "new"
    }));
    setMultiQuery({
      viewid: template.id,
      updateFilters: true
    });
    setLaoNewView({ open: false })
  }

  const resetTableFilters = () => {
    setMultiQuery({
      laoFilters: JSON.stringify(generateLaoURLFilters(activeView.filters))
    });
  }

  const laoSaveView = () => {
    dispatch(updateLAOView({
      view: {
        ...activeView,
        filters: laoRemappedFilters,
        columns: columns,
      }, tableName: laoViewTableNames[tableName]
    }))
  }

  const laoDeleteView = () => {
    dispatch(deleteLAOView({
      viewid: activeView.id,
      tableName: laoViewTableNames[tableName]
    }));
    setMultiQuery({
      viewid: undefined,
      laoFilters: "{}"
    });
  }

  useEffect(() => {
    return () => {
      dispatch(resetLAOViews({
        tableName: laoViewTableNames[tableName]
      }));
    };
  }, []);

  return (
    <Grid container data-testid="laoFilterBar" className={classes.root}>
      {
        (viewLoading || loading) &&
        <LinearProgress className={classes.progress} />
      }
      <Snack {...snack} handleClose={() => setSnack({ open: false })} />
      <Grid item container alignItems="center" xs={12} sm={12} md={9} lg={8}>
        <LaoFilterChips
          type={type}
          subtype={tableName}
          filterBody={filterBody}
          laoDeleteChip={deleteChip}
          laoRemappedFilters={laoRemappedFilters}
        />
      </Grid>
      <Grid item container alignItems="center" justify="space-around" xs={3} sm={3} md={1} lg={1} className={classes.divider}>
        <Grid item>
          <Link onClick={clearAllFilters} data-testid="clearLaofilters" className={classes.textlink} > {t('clear_all')} </Link>
        </Grid>
        {laoViewApplied && <Grid item>
          <Link data-testid="resetLaofilters" onClick={resetTableFilters} className={classes.textlink} > {t('reset')} </Link>
        </Grid>}
      </Grid>
      <Grid item container justify="space-around" alignItems="center" xs={12} sm={12} md={2} lg={3} className={classes.laoViewActions}>
        {
          !laoNewView.open && <>
            {
              laoViewApplied &&
              <Grid item xs={12}>
                <Typography align='center' variant='h4'> {activeView.name} </Typography>
              </Grid>
            }
            <Grid item>
              <IconButton data-testid="newViewIcon" onClick={() => setLaoNewView({ open: true })} >
                <img src={FilterViewIcon} fontSize='medium' />
              </IconButton>
              <Typography variant='subtitle1'> {t('new_view')} </Typography>
            </Grid>
            {
              laoViewApplied &&
              <>
                <Grid item>
                  <IconButton data-testid="saveview-btn" onClick={laoSaveView}>
                    <SaveOutlinedIcon fontSize="medium" className={classes.saveIcon} />
                  </IconButton>
                  <Typography variant='subtitle1'> {t('save')} </Typography>
                </Grid>
                <Grid item>
                  <IconButton data-testid="delview-btn" onClick={laoDeleteView}>
                    <DeleteForeverOutlinedIcon />
                  </IconButton>
                  <Typography variant='subtitle1'> {t('delete')} </Typography>
                </Grid>
              </>
            }
          </>
        }
        {laoNewView.open &&
          <Grid item>
            <form onSubmit={laoAddView} >
              <FormGroup row
                className={classes.addview}>
                <TextField
                  value={laoNewView.name}
                  onChange={(e) => setLaoNewView({ open: true, name: e.target.value })}
                  variant="outlined"
                  required
                  className={classes.laoAddText}
                  autoComplete={false}
                  inputProps={{
                    "data-testid": "save_new_from_view",
                    "autocomplete": "off"
                  }}
                  name="viewname"
                  placeholder={t('enter_a_view_name')}
                  size="small"
                />
                <Button
                  className={classes.laoSaveButton}
                  variant="contained"
                  size="small"
                  type="submit"
                  disableElevation
                  disabled={isEmpty(laoNewView.name)}
                  data-testid="addview_submit">
                  {t('save')}
                </Button>
                <Button
                  className={classes.laoCancelButton}
                  disableElevation
                  variant="contained"
                  onClick={(e) => setLaoNewView({ open: false })}
                  size="small"
                  type="reset"
                  data-testid="addview_cancel">
                  {t('cancel')}
                </Button>
              </FormGroup>
            </form>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default LaoFilterBox;